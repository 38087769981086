@font-face {
  font-family: 'Gotham-Book';
  src: url('../src/fonts/Gotham-Book.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham-Medium';
  src: url('../src/fonts/Gotham-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url('../src/fonts/Gotham-Bold.otf') format('opentype');
}

body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(260deg, #f2fafc -34.06%, #ffffff 77.24%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#google_btn {
  transform: scale(1.5);
}

.recaptcha-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  transform: scale(1.37);
}

@media screen and (max-width: 470px) {
  #google_btn {
    transform: scale(1.25);
  }
  .recaptcha-center {
    transform: scale(1.2);
  }
}

@media screen and (max-width: 400px) {
  #google_btn {
    transform: scale(1.15);
  }
  .recaptcha-center {
    transform: scale(1.1);
  }
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 173px;
  margin-bottom: 44px;
}
